import { breakpoints } from "./utilities.js";
import "./jquery.bxslider.js";

export default () => {

	$('.m-home-banner:not(.js-banner-done)').each(function() {
		var wrapper = $(this);
		var gallery = wrapper.find('.js-gallery');

		if (gallery.find('.js-gallery-video').length) {
			if (window.matchMedia('(min-width: ' + breakpoints.tabletLarge + 'px)').matches) {
				var item = gallery.find('.js-gallery-video');
				item.find('video').show().get(0).play();
			}
		}
		else {
			// slider
			if (gallery.find('.js-gallery-item').length > 1) {

				// init
				var slider = gallery.bxSlider({
					mode: 'ontouchstart' in window && window.matchMedia('(max-width: ' + (breakpoints.tabletLarge - 1) + 'px)').matches ? 'horizontal' : 'fade',
					auto: true,
					speed: 500,
					pause: 7500,
					pager: false
				});

				// interactions
				gallery.find('a').on('click', function() {
					slider.stopAuto();
				});
			}
			return false;
		}

		// done
		wrapper.addClass('js-banner-done');
	});
}

